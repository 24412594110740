body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
.gjoprN {
  justify-content: center !important;
}
.content-wrapper{
  background-color: white;
}
.material-symbols-outlined {
  font-size: 16px;
}
.sidebar .nav:not(.sub-menu) > .nav-item > .nav-link {
    margin: 7px;
}
.nav-link:hover, .nav-link:focus{
  background-color: #1e1e1e !important;
}



.sidebar .nav .nav-item.active > .nav-link{
  background-color: #1e1e1ec8 !important;
}
.sidebar .nav:not(.sub-menu) > .nav-item.active{
  background-color: #1e1e1e !important;
}
.navbar .navbar-menu-wrapper, .navbar .navbar-brand-wrapper{
  background-color: #1e1e1e !important;
}
.sidebar .nav.sub-menu{
  background-color: #1e1e1e !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.sidebar{
  width: 230px;
}
.modal{
  display: flex !important;
}
.btn-outline-dark:hover{
  background-color: #1e1e1e !important;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #1e1e1e;
}
.mll{
  margin-left: -5px !important;
}

@media screen and (max-width: 991px){
  .sidebar-offcanvas {
    left: -230px;
}

.sidebar-offcanvas.active {
    left: 0px;
}

}
.modal-content{
  width: 130%;
}
#a{
  color:#1e1e1e;
}
#a:hover, #a:visited, #a:focus, .active{
  color:#fff !important;
}
/* .active{
  color:#1e1e1e;
} */
.sidebar .nav.sub-menu{
  padding: 10px;
  margin:6px;
}
.table td img {
  height: 50px;
  width: 50px;
}
.table tbody td {
  text-transform: capitalize;
  padding: 0.3rem 1rem;
}
.btn-dark, .bg-dark{
  background-color: #1e1e1e !important;
  color: aliceblue;
}
/* .nav-item:hover{
  background-color: #1e1e1e !important;
} */
/* a:hover{
  background-color: #1e1e1e !important;
} */
.custom-hover:hover{
  color: #1e1e1e !important;
}
.content-wrapper {
  padding: 2rem;
}
a .nav-link:visited{
  background-color: #1e1e1e !important;
}
.sidebar .nav:not(.sub-menu) > .nav-item > .nav-link[aria-expanded="true"]{
  background-color: #1e1e1e !important;
}
.wap {
  color: #25d366;
}
.wap:hover {
  color: #18ad4f;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #25d366;
}

.vendor-details p {
  font-size: large;
}
.vendor-details span {
  font-size: small;
}

.pointer {
  cursor: pointer;
}

.fa-sort-amount-desc:before {
  content: "\f160";
}
.fa-sort-amount-asc:before {
  content: "\f161";
}
ul li,
ol li,
dl li {
  line-height: 1.3;
  margin-top:0.5rem;
  margin-bottom: -0.5rem;
  font-size: larger;
}

.isPiMa{
  display: inline-flex;
  justify-content: center !important;
}
.pending {
  background-color: var(--yellow);
  border-radius: 5px;
  font-weight: 700;
  /* padding-top: 0.3rem;
  padding-bottom: -0.3rem; */
}
.accept {
  background-color: var(--green);
}
.shipped {
  background-color: var(--blue);
}
.cancel {
  background-color: var(--red);
}
.deliver {
  background-color: var(--success);
}

.model-title{
  position: relative;
}

.btn-close{
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background-image: url(https://cdn.icon-icons.com/icons2/1769/PNG/512/4115230-cancel-close-cross-delete_114048.png);
  height: 1.5rem;
  background-color: transparent;
  padding-bottom:1rem ;
  background-repeat: no-repeat;

  width: 1.5rem;
  background-size: contain;
  border: none;
}

.example-modal-sizes-title-sm-csv{
  padding-top: 1rem;
}

.button-container{
  width: 30rem;
  margin: 0;
  padding: 0;
}
.button-container > button:nth-child(1),.button-container > button:nth-child(2){
    /* background-color:yellow; */
    /* padding: 1rem 6rem; */
    max-width: unset;
}
.search-div{
  max-width: 23.66667%;
  padding: 0;
}
.total-vendor-count{
  padding-top: 1rem;
    padding-right: 1rem;
}
.vendor-download-icon-button{
  max-width: unset;
    width: 2rem;
    position: relative;
    padding: 1.3rem;
}
.vendor-download-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  
}
.vendor-out-container{
  margin: 0 !important;
    padding: 0 !important;
    
}

.input-date-time{
  width: 10rem;
}
.dashboard-date-submit-button{
  flex: 0;
}

.button-group{
  margin-right:1rem ;
  margin-top: 1rem;
  margin-left: 1rem;
}

.unit-button-category{
  margin-top: 1rem;
}

.unit-form{
  margin-top: 1rem;
  margin-left: 1rem;
}
.sl-column{
  padding-right:0 !important;
}
.logo-column{
  padding-left: 0 !important;
}
td img{
  border-radius: unset !important;
}
.banner-icon{
  background-image: url("https://cdn-icons-png.flaticon.com/512/4442/4442051.png") !important;
}
.active-inactive-toggle-parent{
  display: flex;

}
.active-inactive-toggle{
  display: flex;
    /* flex-direction: column; */
    column-gap: 0.5rem;
    margin-right: 1rem;
  }
  .dashboard-visual-totals .card-body{
    padding: 0.8rem;
  }

  .dashboard-visual-totals .card-body h5{
    margin-bottom: 0.6rem !important;
  }
.nav-link-flex {
  flex-grow: 1;
  text-align: center; /* Optional: Centers the text within the link */
}

  .btnn{
    font-size: 1.2rem;
    border-radius: 35%;
  }
  .eGxbrW {
    display: flex;
    justify-content: center !important;
  }