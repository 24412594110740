/* Sidebar.css */

/* Base styles for the collapse divs */
.collapse {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out !important;
}

/* When the collapse is shown */
.collapse.show {
  max-height: 500px; /* Adjust this value to fit the content */
}

/* Optional: Adjust the menu-arrow style */
.menu-arrow {
  transition: transform 0.3s ease-out !important;
}

.nav-item a.collapsed .menu-arrow {
  transform: rotate(-90deg);
}
